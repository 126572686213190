import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Nav from "./nav"
import NavRight from "./navRight"
import Icon from "../images/icons/logoIcon.svg"
import IconLogo from "../images/icons/AventuraLogo.svg"
import NavDrawer from "../components/elements/DrawerNav"

const StyledHeader = styled.header`
  background: transparent;
  position: sticky;
  display: flex;
  justify-content: space-between;
  height: 100px;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0 5%;
  z-index: 1000;

  @media ${props => props.theme.sizes.tablet} { 
    padding: 0 2.5rem;
    }

`

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  transition: 2s all;
  width: 100%;
  justify-content: center;

  .bigLogo {
    display: flex;
  }

  svg {
    max-width: 50%;
  }
  @media ${props => props.theme.sizes.mobileM} {
    .bigLogo {
        max-width: 40%;
    }
  }
  @media ${props => props.theme.sizes.mobileL} {
    .bigLogo {
        max-width: 24%;
    }
  }

  @media ${props => props.theme.sizes.tablet} { 
    display: flex;
    width: 20%;
    
    .bigLogo {
      margin-top: 15%;
      max-width: 50%;
    }

    svg {
      max-width: 100%;
    }
  }
`

export default function Header() {
  const [pos, setPos] = useState("top")
  const [home, setHome] = useState("yes")


  useEffect(() => {
    if(document.location.pathname === "/"){
      setHome("yes")
    } else {
      setHome("no")
    }
    document.addEventListener("scroll", e => {
      if(document.location.pathname === "/"){
        let scrolled = document.scrollingElement.scrollTop
        if (scrolled >= 600) {
          setPos("moved")
        } else {
          setPos("top")
        }
        } else {
          let scrolled = document.scrollingElement.scrollTop
          if (scrolled >= 100) {
            setPos("moved")
          } else {
            setPos("top")
          }
        }

    })
  }, [])

  return (
    <StyledHeader
      style={{
        backgroundColor: pos === "top" ? "" : "#fff",
      }}
    >
      <Nav />
      {home === "yes"
        ? 
        <LogoLink  to="/" >
          <Icon style={{ display: pos === "top" ? "none" : "flex", maxWidth: 100 }} />
        </LogoLink>
        :  
        <LogoLink  to="/" >
          <Icon style={{ display: pos === "top" ? "none" : "flex", maxWidth: 100 }} />
          <IconLogo className="bigLogo" style={{ display: pos === "top" ? "flex" : "none"}}/>
        </LogoLink>
      }

      <NavRight />
      <NavDrawer/>
    </StyledHeader>
  )
}