/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled, { ThemeProvider } from "styled-components"
import Helmet from "react-helmet"

import Theme from "../styles/Theme"

import Header from "./header"
import Footer from "./sections/footer"
import "./layout.css"

const SiteRoot = styled.div`
  background: white;
  margin-top: -100px;
`

const Layout = ({ children }) => {

  return (
    <SiteRoot>
      <Helmet title="Aventura">
        <link rel="preconnect" href="https://datocms-assets.com" />
      </Helmet>
      <ThemeProvider theme={Theme}>
        <>
          <Header />
          <main>{children}</main>
          <Footer/>
        </>
      </ThemeProvider>
    </SiteRoot>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
