/* eslint-disable quotes */
const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  tabletL: '820px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '1900px',
  fullHD: '1970px',
  desktopL: '2560px'
}

const Theme = {
  colors: {
    primary: "#241d50",
    secondary: '#82a0bc',
  },
  effects: {
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
  },
  fonts: {
    primary: `'Pacifico', cursive`,
    secondary: `'Josefin Sans', sans-serif`,
    text: `'Montserrat', sans-serif`,
  },
  sizes: {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    fullHD: `(min-width: ${size.fullHD})`,
    desktopL: `(min-width: ${size.desktopL})`
  },
}

export default Theme


