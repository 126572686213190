import React from "react"
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Navigation = styled.nav`
  display: none;
  justify-content: flex-start;
  padding: 2rem 0;


  @media ${props => props.theme.sizes.laptop} { 
    display: flex;
    width: 40%;
  }
`
const StyledLink = styled(AnchorLink)`
  font-size: 1.5rem;
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 300;
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  margin-right: 3rem;

  @media ${props => props.theme.sizes.tablet} { 
    font-size: 1.1rem;
    margin-right: 2rem;
  }
  @media ${props => props.theme.sizes.desktop} { 
    font-size: 1.5rem;
    margin-right: 3rem;
  }

  &:last-of-type {
    margin-right: 0;
  }
`

const Nav = () => {
  return (
    <Navigation>
      <StyledLink to="/#banner">Home</StyledLink>
      <StyledLink to="/#kolonie">Oferta</StyledLink>
      <StyledLink to="/#oNas">O nas</StyledLink>
    </Navigation>
  )
}

export default Nav
