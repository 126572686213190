import React from "react"
import styled from "styled-components"
import IconLogo from "../../images/icons/AventuraLogo.svg"

const StyledFooter = styled.footer`
    font-family: ${props => props.theme.fonts.secondary};   
    background: ${props => props.theme.colors.secondary};
  position: sticky;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 0;
  left: 0;
  padding: 2% 5%;
  z-index: 1000;
  flex-direction: column;

  @media ${props => props.theme.sizes.tablet} { 
    flex-direction: row;
  }

  .footer {
      &__wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          @media ${props => props.theme.sizes.tablet} { 
            flex-direction: row;
            width: 100%;
            flex-wrap: wrap;
            justify-content: space-around;
          }
          @media ${props => props.theme.sizes.laptopL} { 
            justify-content: space-between;
          }
      }
      &__column {
        @media ${props => props.theme.sizes.tablet} {
          width: 50%;
        }
      }
      &__left, &__right {
        display: flex;
        flex-direction: column;
        order: 2;
        width: 100%;

        @media ${props => props.theme.sizes.tablet} {
          flex-direction: row;
        }

        @media ${props => props.theme.sizes.laptop} { 
          width: 50%;
        }
        @media ${props => props.theme.sizes.desktop} {
          order: unset;
          width: unset;
        }
      }
      &__data, &__media, &__documents {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin: 1rem 0;
          order: 2;

          @media ${props => props.theme.sizes.tablet} { 
            margin: 1rem 3rem 1rem 1rem;
          }
          @media ${props => props.theme.sizes.laptopL} { 
            order: unset;
            margin: 1rem 1.5rem;
          }
          span {
            font-size: 0.9rem;
          }

          .bold {
            font-weight: 600;
          }

          .marginB {
            margin-bottom: .5rem;
          }

          h4 {
              font-size: 1rem;
              margin-bottom: 5px;
              font-family: ${props => props.theme.fonts.secondary};   
              font-weight: 700;
              
          }

          a {
            font-size: 0.9rem;
            font-family: ${props => props.theme.fonts.secondary};   
            color: ${props => props.theme.colors.primary};
            text-decoration: none;
          }
          li {
            margin-bottom: 0;
          }
      }
  }

`

const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media ${props => props.theme.sizes.tablet} { 

  }
  @media ${props => props.theme.sizes.laptopL} { 
    
  }
  @media ${props => props.theme.sizes.desktop} {
    width: 20%;
    order: unset;
    margin: 0 2rem;
  }
`

const LogoLink = styled.div`
  display: flex;
  align-items: center;
  transition: 2s all;
  width: 100%;
  padding: 1rem 0;
  order: 1;

  svg {
    max-width: 50%;
  }

  @media ${props => props.theme.sizes.tablet} { 
    display: flex;
    justify-content: center;
    width: 20%;
    
    svg {
      max-width: 100%;
    }
  }
  @media ${props => props.theme.sizes.laptopL} { 
    order: unset;
  }
  @media ${props => props.theme.sizes.desktop} {
    margin: 0 4rem;
    width: 100%;
  }
`

export default function Footer() {



  return (
    <StyledFooter>
                
        <div className="footer__wrapper">
          <div className="footer__left">
            <div className="footer__column footer__data">
                  <span>Aventura Wojciech Tomecki</span>
                  {/* <span className="marginB">Wojciech Tomecki</span> */}
                  <span>ul. Henryka Sienkiewicza 29B/31</span>
                  <span>44-190 Knurów</span>
              </div>
              <div className="footer__column footer__media">
                  <span className="bold">Biuro w Knurowie:</span>
                  <span className="marginB">ul. Szpitalna 8, p.210</span>
                  <a href="tel:+48 723 368 064">+48 723 368 064</a>
                  <a href="mailto:aventura.kontakt@gmail.com">aventura.kontakt@gmail.com</a>

              </div>
          </div>
          <LogoWrapper>
            <LogoLink><IconLogo/></LogoLink>
          </LogoWrapper>

          <div className="footer__right">
            <div className="footer__column footer__data footer__data__second">
                  
                  <span>NIP.:9691639056</span>
                  <span>wpis do ROTiPT nr 1435</span>
                  <span>ING Bank Śląski S.A.</span>
                  <span>04 1050 1298 1000 0097 1999 6713</span>
              </div>

              <div className="footer__column footer__documents">
                  <h4>Dokumenty do pobrania:</h4>
                  <ul>
                    <li><a target="_blanc" href="https://wordpress.aventura.turystyka.pl/wp-content/uploads/2021/10/Certyfikat.pdf" download="pdf">Gwarancja Ubezpieczeniowa</a></li>
                    <li><a target="_blanc" href="http://wordpress.aventura.turystyka.pl/wp-content/uploads/2021/01/OBOWIAZEK-INFORMACYJNY-Klauzula-dot.-przetwarzania-danych-osobowych-klienci.pdf" download="pdf">RODO</a></li>
                    <li><a target="_blanc" href="http://wordpress.aventura.turystyka.pl/wp-content/uploads/2021/02/ksiegaEwidencyjna-3.pdf" download="pdf">Wpis do Rejestru Organizatorów Turystyki&#65279;</a></li>
                    <li><a target="_blanc" href="https://aventura.turystyka.pl/dokumenty/Formularz-informacyjny-do-umów.pdf" download="pdf">Formularz informacyjny do umów&#65279;</a></li>
                  </ul>
              </div>
          </div>
        </div>
    </StyledFooter>
  )
}
