import React from "react"
import styled from "styled-components"

const Navigation = styled.nav`
  display: none;
  justify-content: flex-end;
  padding: 2rem 0 ;
  

  @media ${props => props.theme.sizes.laptop} { 
    display: flex;
    width: 40%;
  }
`
const StyledLink = styled.a`
  font-size: 1.5rem;
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  margin-right: 3rem;

  @media ${props => props.theme.sizes.tablet} { 
    font-size: 1.1rem;
    margin-right: 2rem;
  }
  @media ${props => props.theme.sizes.desktop} { 
    font-size: 1.5rem;
    margin-right: 3rem;
  }

  &:last-of-type {
    margin-right: 0;
  }
`

const NavRight = () => {
  return (
    <Navigation>
      <StyledLink href="tel: +48 723 368 064">+48 723 368 064</StyledLink>
      <StyledLink href="mailto: aventura.kontakt@gmail.com">aventura.kontakt@gmail.com</StyledLink>
    </Navigation>
  )
}

export default NavRight
