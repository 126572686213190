import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import { AnchorLink } from "gatsby-plugin-anchor-links"
import styled from "styled-components";
import NotesIcon from '@material-ui/icons/Notes';
import { Link } from "gatsby"

const StyledLink = styled(Link)`
  font-size: 2.5rem;
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 300;
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  margin-bottom: 3rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`
const NawigationDraw = styled.div`
  display: flex;

  @media ${props => props.theme.sizes.laptop} { 
      display: none;
    }
`

const ToogleButton = styled.button`
    cursor: pointer; 
    border: none;
    background: none;
    padding: none;
    outline: none;
    margin-top: ${props => props.top || "0"};
`

const useStyles = makeStyles({

  list: {
    width: '70vw',
    height: '100%',
  },
  ListItems: {
    width: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  close: {
      fontFamily: 'Josefin Sans',
      fontSize: '2.5rem',
      fontWeight: '800',
      justifyContent: 'flex-start',
      paddingLeft: '2rem',
      width: '35%',
      color: '#241d50',
  },
  toogle: {
    color: '#241d50',
  },
});

export default function NavDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  const list = (anchor) => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className={clsx(classes.ListItems)}>
            <StyledLink to="/#banner">Home</StyledLink>
            <StyledLink to="/#oNas">O nas</StyledLink>
            <StyledLink to="/#kolonie">Kolonie</StyledLink>
      </List>
    </div>
  );

  return (
    <NawigationDraw>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <ToogleButton onClick={toggleDrawer(anchor, true)}><NotesIcon style={{ fontSize: 50 }} className={clsx(classes.toogle)}/></ToogleButton>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            <>
            <ToogleButton top="1.5rem"  className={clsx(classes.close)} onClick={toggleDrawer(anchor, false)}>
                X
            </ToogleButton>

            {list(anchor)}
            </>
          </Drawer>
        </React.Fragment>
      ))}
    </NawigationDraw>
  );
}
